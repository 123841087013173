<template>
  <div>
    <div class="search">
      <div class="searchFl">
        <portSelect
            v-model="queryParams.dest_port"
            :placeTitle="'请输入目的港'"
        >
        </portSelect>
        <el-input
            v-model="queryParams.entrust_no"
            placeholder="请输入委托编号"
            size="small"
            class="searchInput"
            clearable
        >
        </el-input>
        <el-input
            v-model="queryParams.mbl_no"
            placeholder="请输入提单号"
            size="small"
            class="searchInput"
            clearable
        >
        </el-input>
        <el-date-picker
            v-model="time"
            type="daterange"
            start-placeholder="委托开始时间"
            end-placeholder="委托结束时间"
            size="small"
            class="searchInput"
        ></el-date-picker>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange">查询</el-button>
      </div>
    </div>
    <div class="tableBlock">
      <!-- 参会人员管理表格 -->
      <el-table
          :data="tableData"
          style="width: 100%; margin-top: 24px"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 74 }"
          :tableLoading="loading"
      >
        <el-table-column label="委托编号" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">{{ scope.row.entrust_no | textFormat }}</div>
        </el-table-column>
        <el-table-column label="起运港" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">
            <div v-if="scope.row.from_port_name_zh">
              {{ scope.row.from_port_name_zh | textFormat }}({{ scope.row.from_port_name_en | textFormat }})
            </div>
            <div v-else>-</div>
          </div>
        </el-table-column>
        <el-table-column label="目的港" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">
            <div v-if="scope.row.dest_port_name_zh">
              {{ scope.row.dest_port_name_zh | textFormat }}({{ scope.row.dest_port_name_en | textFormat }})
            </div>
            <div v-else>-</div>
          </div>
        </el-table-column>
        <el-table-column label="船信息" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">
            <div v-if="scope.row.vessel">{{ scope.row.vessel }}/{{ scope.row.voyage_flight }}</div>
            <div v-else>-</div>
          </div>
        </el-table-column>
        <el-table-column label="货物品名" :show-overflow-tooltip="true" width="200">
          <div slot-scope="scope">{{ scope.row.cargo_name | textFormat }}</div>
        </el-table-column>
        <el-table-column label="件/毛/体" :show-overflow-tooltip="true" width="200">
          <div slot-scope="scope">
            <div v-if="scope.row.amount_unit">
              {{ scope.row.amount | textFormat }}{{ scope.row.amount_unit_code | textFormat }}
              /
              {{ scope.row.gross_weight | textFormat }}KGS
              /
              {{ scope.row.volume | textFormat }}CMB
            </div>
            <div v-else>-</div>
          </div>
        </el-table-column>
        <el-table-column label="开航日期" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">{{ scope.row.etd | secondFormat }}</div>
        </el-table-column>
        <el-table-column label="截单日期" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">-</div>
        </el-table-column>
        <el-table-column label="截关日期" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">-</div>
        </el-table-column>
        <el-table-column label="订舱" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">
            <div v-if="scope.row.booking_status == 0">待订舱</div>
            <div v-if="scope.row.booking_status == 1">已订舱</div>
          </div>
        </el-table-column>
        <el-table-column label="委托日期" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">{{ scope.row.created_time | secondFormat }}</div>
        </el-table-column>
        <el-table-column label="状态" fixed="right" width="100">
          <div slot-scope="scope">
            <div v-if="scope.row.status == 0" style="color: #ee9900">待审核</div>
            <div v-if="scope.row.status == 1" style="color: #ee9900">待提交</div>
            <div v-if="scope.row.status == 2" style="color: #000000">已成单</div>
            <div v-if="scope.row.status == 3" style="color: #666666">已取消</div>
            <div v-if="scope.row.status == 4" style="color: red;">拒绝</div>
          </div>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <div slot-scope="scope">
            <el-button type="text" @click="goMyEntrustDetails(scope.row)">委托详情</el-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
          @current-change="handleChange"
          :page-size="15"
          layout="total, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import portSelect from "~ymp/components/common/portSelect.vue"

export default {
  name: "list",
  components: {portSelect},
  data() {
    return {
      loading: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      queryParams: {
        entrust_no: '',
        dest_port: '',
        mbl_no: '',
      },
      time: null
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        user_id: this.USER_INFO.id,
        entrust_no: this.queryParams.entrust_no,
        dest_port: this.queryParams.dest_port,
        mbl_no: this.queryParams.mbl_no,
        limit: 15,
        start: this.currentPage - 1,
      };
      if (this.time) {
        if (this.time[0]) {
          params.start_time = this.$moment(this.time[0]).unix();
        }
        if (this.time[1]) {
          params.end_time = this.$moment(this.time[1]).unix() + 86399;
        }
      }
      let result = await this.$store.dispatch(
          "baseConsole/getBookingEntrustList",
          params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
      }
    },
    searchChange() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    goMyEntrustDetails(row) {
      if (row.status == 2) {
        this.$router.push({
          path: '/console/myEntrust/detailsOrdered',
          query: {
            id: row.id,
            order_id: row.order_id
          }
        })
      } else {
        let id = row.id
        let type = row.entrust_type
        let status = row.status
        this.$router.push({
          path: '/console/myEntrust/details',
          query: { id, type, status }
        })
      }

    }
  }
}
</script>
<style scoped lang="less">
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 100%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }

}
</style>